import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const PriceSticker: React.FunctionComponent<{ price: string; from?: boolean; className?: string }> = ({
  price,
  from = false,
  className = ``,
}) => {
  const { t } = useTranslation()

  return (
    <div className={`price-sticker ${className}`}>
      {from && <span className="price-sticker__from">{t(`from_price`)}</span>}
      <span className="price-sticker__currency">€</span>
      <span className="price-sticker__amount">{price}</span>
      <span className="price-sticker__rent-period">/MON</span>
    </div>
  )
}

export default PriceSticker
