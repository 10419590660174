import React from 'react'
import { SliderBlock } from '../../../../../shared/types/cms'
import Carousel from '../carousel'
import Markdown from '../markdown'

const SliderBlockComponent: React.FC<{ data: SliderBlock }> = ({ data }) => {
  return (
    <div className="page-block page-block-slide">
      <div className="container-lg">
        <div className="row">
          <div className="col-12">
            <div className="page-block-slide__feature-list">
              <Carousel id="page-block-slide__feature-list">
                {data.slides.map((slide) => (
                  <div
                    key={`slide_` + slide.id}
                    className="page-block-slide__feature"
                    style={{ '--bg-image': `url('${slide.background?.url}')` } as React.CSSProperties}
                  >
                    <div className="page-block-slide__feature-title">{slide.title}</div>
                    <div className="page-block-slide__feature-text">
                      <Markdown>{slide.description ?? ``}</Markdown>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SliderBlockComponent
