import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import MainLayout from '../components/main-layout'
import '../styles/main.scss'
import PageWrapper from '../components/page/page-wrapper'
import SearchProvider from '../contexts/searchContext'
import Header from '../components/header/header'
import Footer from '../components/footer'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Helmet } from 'react-helmet'
import { Page } from '../../../../shared/types/cms'
import PageBlockConsumer from '../components/page/page-blocks-consumer'

interface IndexPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        titleTemplate: string
      }
    }
    strapiPages: Page
  }
}

export const pageQuery = graphql`
  query IndexQuery($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        titleTemplate
      }
    }
    strapiPages(slug: { eq: "/" }, locale: { eq: $language }) {
      locale
      title
      blocks {
        __typename
        strapi_component
        ... on PageRichTextBlock {
          text
        }
        ... on PageSliderBlock {
          slides {
            id
            title
            description
            background {
              url
            }
          }
        }
        ... on PageDynamicBlock {
          type
        }
        ... on PageFaqsBlock {
          faqs {
            id
            question
            answer
          }
        }
        ... on PageImageBlock {
          images {
            id
            url
            alternativeText
          }
        }
        ... on PageInfoBlock {
          id
          title
          description
          caption
          image {
            url
          }
          imageCaption
          imagePosition
          link {
            url
            title
            target
          }
        }
      }
      slug
      seo {
        meta {
          content
          id
          name
        }
        id
        metaDescription
        metaTitle
        preventIndexing
      }
    }
  }
`

export const IndexPage: React.FC<IndexPageProps> = (props) => {
  const { t } = useTranslation()

  const pageBlocks = props.data.strapiPages?.blocks ?? []

  const renderMeta = () => {
    const metaTags: JSX.Element[] = []
    if (props.data.strapiPages?.seo) {
      metaTags.push(
        <meta key="meta_description" name="description" content={props.data.strapiPages.seo.metaDescription} />,
      )
      metaTags.push(<meta key="meta_title" name="title" content={props.data.strapiPages.seo.metaTitle} />)

      if (props.data.strapiPages.seo.preventIndexing) {
        metaTags.push(<meta key="meta_noindex" name="robots" content="noindex" />)
      }

      if (props.data.strapiPages.seo.structuredData !== null) {
        metaTags.push(
          <script key="meta_structureddata" type="application/ld+json">
            {props.data.strapiPages.seo.structuredData}
          </script>,
        )
      }

      if (props.data.strapiPages.seo.meta) {
        props.data.strapiPages.seo.meta.forEach((metaEntry) => {
          metaTags.push(<meta key={`meta_${metaEntry.id}`} name={metaEntry.name} content={metaEntry.content} />)
        })
      }
    }
    return metaTags
  }

  return (
    <>
      <PageWrapper>
        <SearchProvider>
          <MainLayout className="home__container">
            <Helmet title={t(`Home`)} titleTemplate={props.data.site.siteMetadata.titleTemplate}>
              {renderMeta()}
            </Helmet>
            <Header variant="home" />
            <div className="home">
              <PageBlockConsumer blocks={pageBlocks} />
            </div>
            <Footer />
          </MainLayout>
        </SearchProvider>
      </PageWrapper>
    </>
  )
}
export default IndexPage
