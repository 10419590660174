import { Link } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { InfoBlock } from '../../../../../shared/types/cms'
import Markdown from '../markdown'
import curvedArrowDownright from '../../images/curved-arrow-downright.png'
import ChevronRightRoundIcon from '../../images/chevron-right-round.inline.svg'

const InfoBlockComponent: React.FC<{ data: InfoBlock }> = ({ data }) => {
  return (
    <div className={`page-block page-block-info page-block-info--${data.imagePosition}`}>
      <div className="container-lg">
        <div className="row">
          <div
            className="page-block-info__image"
            style={{ backgroundImage: data?.image?.url ? `url('${data.image.url}')` : undefined }}
          >
            {data?.imageCaption && (
              <>
                <div className="page-block-info__image-caption-shadow" />
                <div className="page-block-info__image-caption">
                  <img className="page-block-info__image-arrow" src={curvedArrowDownright} />
                  {data.imageCaption}
                </div>
              </>
            )}
            <img className="page-block-info__image-mobile" src={data?.image?.url ?? undefined} />
            {data?.link?.url && (
              <Link className="page-block-info__link" to={data.link.url}>
                {data.link.title} <ChevronRightRoundIcon className="page-block-info__link-chevron" />
              </Link>
            )}
          </div>
          <div className="page-block-info__text">
            <div className="page-block-info__text-caption">{data.caption}</div>
            <div className="page-block-info__text-title">{data.title}</div>
            <div className="page-block-info__text-description">
              <Markdown>{data.description}</Markdown>
            </div>
            {data?.link?.url && (
              <Link className="page-block-info__link" to={data.link.url}>
                {data.link.title} <ChevronRightRoundIcon className="page-block-info__link-chevron" />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoBlockComponent
