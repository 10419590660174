import React from 'react'
import { ImageBlock } from '../../../../../shared/types/cms'

const ImageBlockComponent: React.FC<{ data: ImageBlock }> = ({ data }) => {
  return (
    <div className="page-block page-block-image">
      <div className="container-lg">
        <div className="row">
          {data.images.map((image) => {
            return (
              <div className="page-block-image__image" key={`image_` + image.id}>
                <img src={image.url} alt={image.alternativeText ?? ``} />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ImageBlockComponent
