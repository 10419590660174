import React from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from '../markdown'
import { graphql, useStaticQuery } from 'gatsby'
import { RoomService } from '../../../../../shared/types/cms'
import { CmsRoomServices } from '../../services/cms'
import { useI18next } from 'gatsby-plugin-react-i18next'
import { RoomServiceType } from '../../../../../shared/types/RoomServiceType'
import defaultServiceIcon from '../../images/icon-circle-check-outline.svg'

export const query = graphql`
  query {
    allStrapiRoomServices {
      edges {
        node {
          id
          name
          description
          nameEn
          descriptionEn
          type
          icon {
            url
          }
          image {
            url
          }
          imageEn {
            url
          }
        }
      }
    }
  }
`

const ServicesBlock: React.FC = () => {
  const { language } = useI18next()
  const staticData = useStaticQuery(query)
  const roomServices: { node: RoomService }[] = staticData.allStrapiRoomServices.edges

  const standardServices = React.useMemo<RoomService[]>(() => {
    return roomServices
      .filter((service) => service.node.type === RoomServiceType.Standard)
      .map((service) => service.node)
  }, [staticData])
  /*const ondemandServices = React.useMemo<RoomService[]>(() => {
    return roomServices
      .filter((service) => service.node.type === RoomServiceType.OnDemand)
      .map((service) => service.node)
  }, [staticData])*/

  return (
    <div className="page-block page-block-services">
      <div className="container-lg">
        <div className="page-block-services__list row justify-content-center">
          {standardServices.map((service) => (
            <div key={service.id} className="col page-block-services__service-wrapper">
              <div
                className="page-block-services__service"
                style={{
                  backgroundImage: `url('${
                    CmsRoomServices.getLocalImage(service, language)?.url ?? defaultServiceIcon
                  }')`,
                }}
              >
                <div className="page-block-services__service-content">
                  <h3 className="page-block-services__service-name">
                    {CmsRoomServices.getLocalName(service, language)}
                  </h3>
                  <Markdown>{CmsRoomServices.getLocalDescription(service, language)}</Markdown>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ServicesBlock
