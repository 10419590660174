import React from 'react'
import { DynamicBlock, Page } from '../../../../../shared/types/cms'
import ShortFaqBlockComponent from './short-faq-block-components'
import FullFaqBlockComponent from './full-faq-block-components'
import ImageBlockComponent from './image-block-component'
import LocationsBlock from './locations-block-components'
import RichTextBlockComponent from './richtext-block-component'
import ServicesBlock from './services-block-components'
import SliderBlockComponent from './slider-block-component'
import InfoBlockComponent from './info-block-component'
import VirtualTourBlockComponent from './virtual-tour-block-component'

const DynamicBlockComponent: React.FC<{ data: DynamicBlock }> = ({ data }) => {
  switch (data.type) {
    case `services`:
      return <ServicesBlock />
    case `locations`:
      return <LocationsBlock />
    case `cookie_policy`:
      return (
        <div className="page-block page-block-cookie_policy">
          <div className="page-block-cookie_policy__wrapper container-lg">
            <div id="cookiefirst-policy-page" />
          </div>
        </div>
      )
    case `cookies_table`:
      return (
        <div className="page-block page-block-cookies_table">
          <div className="page-block-cookies_table__wrapper container-lg">
            <div id="cookiefirst-cookies-table" />
          </div>
        </div>
      )
    default:
      return null
  }
}

const PageBlockConsumer: React.FC<{ blocks: Page['blocks'] }> = ({ blocks }) => {
  const pageBlocks: Array<JSX.Element> = []
  blocks.forEach((block: Page['blocks'][0] & { strapi_component?: Page['blocks'][0]['__component'] }, index) => {
    // gatsby-strapi-source replaces "__component" with "strapi_component", we must conciliate typescript and gatsby
    if (block.strapi_component !== undefined) {
      block.__component = block.strapi_component

      const newBlock =
        block.__component === `content.rich-text` ? (
          <RichTextBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.dynamic-block` ? (
          <DynamicBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.slider` ? (
          <SliderBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.short-faq` ? (
          <ShortFaqBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.full-faq` ? (
          <FullFaqBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.image` ? (
          <ImageBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.info-block` ? (
          <InfoBlockComponent key={`page_block_` + index} data={block} />
        ) : block.__component === `content.virtual-tour` ? (
          <VirtualTourBlockComponent key={`page_block_` + index} data={block} />
        ) : null
      if (newBlock !== null) pageBlocks.push(newBlock)
    }
  })

  return <>{pageBlocks}</>
}

export default PageBlockConsumer
