import React from 'react'
import { RichTextBlock } from '../../../../../shared/types/cms'
import Markdown from '../markdown'

const RichTextBlockComponent: React.FC<{ data: RichTextBlock }> = ({ data }) => {
  return (
    <div className="page-block page-block-richtext">
      <div className="page-block-richtext__wrapper container-lg">
        <Markdown>{data.text}</Markdown>
      </div>
    </div>
  )
}

export default RichTextBlockComponent
