import * as React from 'react'

type CarouselProps = {
  id: string
}

const Carousel: React.FC<CarouselProps> = ({ id, children }) => {
  const carouselRefCallback = React.useCallback((node) => {
    if (typeof window !== `undefined` && node !== null) {
      // bootstrap is not ssr friendly, import at the top broke dev
      import(`bootstrap/js/dist/carousel`).then((module) => {
        const Carousel = module.default

        new Carousel(node, {
          interval: 10000,
        })
      })
    }
  }, [])

  return (
    <div className="carousel slide" ref={carouselRefCallback} id={id}>
      <div className="carousel-indicators">
        {React.Children.map(children, (_, index) => (
          <button
            className={`${index === 0 ? `active` : ``}`}
            key={index}
            type="button"
            data-bs-target={`#${id}`}
            data-bs-slide-to={index}
          />
        ))}
      </div>
      <div className="carousel-inner">
        {React.Children.map(children, (child, index) => (
          <div className={`carousel-item ${index === 0 ? `active` : ``}`} key={index}>
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Carousel
