import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'
import { SearchContext } from '../../contexts/searchContext'
import { useLocationOptions } from '../../hooks/useLocationOptions'
import defaultBackground from '../../images/header-background.jpg'
import PriceSticker from '../price-sticker'

const LocationsBlock: React.FC = () => {
  const { locations } = useLocationOptions()
  const { t } = useTranslation()
  const { getUrlSearchParams } = React.useContext(SearchContext)

  return (
    <div className="page-block page-block-locations">
      <div className="container-lg">
        <div className="row row-cols-1 row-cols-md-2">
          {locations.map((location) => {
            const backgroundImage =
              location.backgroundImage?.formats?.small?.url ?? location.backgroundImage?.url ?? defaultBackground

            return (
              <div key={location.slug} className="col page-block-locations__location-wrapper">
                <Link to={`/listings/${getUrlSearchParams({ location: Number(location.id).toString() })}`}>
                  <div className="page-block-locations__location-info">
                    <div className="page-block-locations__location-name">{t(location.name)}</div>
                    {!!location.startingPrice && (
                      <>
                        <PriceSticker price={(location.startingPrice ?? 0).toFixed(0)} from />
                      </>
                    )}
                  </div>
                  <div
                    className="page-block-locations__location"
                    style={{ backgroundImage: `url('${backgroundImage}')` }}
                  />
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default LocationsBlock
