import React from 'react'
import { FaqsBlock } from '../../../../../shared/types/cms'
import Collapsible from '../collapsible'
import Markdown from '../markdown'
import faqBackground from '../../images/faq-background.jpg'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import ChevronRight from '../../images/chevron-right-round.inline.svg'

const ShortFaqBlockComponent: React.FC<{ data: FaqsBlock }> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div className="page-block page-block-short-faq">
      <div className="container-lg page-block-short-faq__wrapper">
        <img className="page-block-short-faq__background" src={faqBackground} />

        <div className="page-block-short-faq__content">
          <div className="page-block-short-faq__left">
            <div className="page-block-short-faq__left-small">{t(`Noch fragen?`)}</div>
            <div className="page-block-short-faq__left-big">{t(`Fragen`)}</div>
          </div>
          <div className="page-block-short-faq__right">
            {data.faqs.map((faq) => (
              <div key={`faq_` + faq.id} className="page-block-short-faq__faq-wrapper">
                <div className="col-12 page-block-short-faq__faq">
                  <Collapsible
                    name="page-block-short-faq-collapse"
                    id={`page-block-short-faq-${faq.id}`}
                    title={faq.question}
                  >
                    <div className="page-block-short-faq__faq-answer">
                      <Markdown>{faq.answer}</Markdown>
                    </div>
                  </Collapsible>
                </div>
              </div>
            ))}
            <Link className="page-block-short-faq__faq-link" to={`/faq`}>
              {t(`Show all`)}
              <ChevronRight />
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShortFaqBlockComponent
