import React from 'react'
import { FaqsBlock } from '../../../../../shared/types/cms'
import Collapsible from '../collapsible'
import Markdown from '../markdown'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const FullFaqBlockComponent: React.FC<{ data: FaqsBlock }> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <div className="page-block page-block-full-faq">
      <div className="container-lg page-block-full-faq__wrapper">
        <h1 className="page-block-full-faq__title">{t(`FAQ`)}</h1>
        {data.faqs.map((faq) => (
          <div key={`faq_` + faq.id} className="page-block-full-faq__faq-wrapper">
            <div className="col-12 page-block-full-faq__faq">
              <Collapsible
                name="page-block-full-faq-collapse"
                id={`page-block-full-faq-${faq.id}`}
                title={faq.question}
                multiple
              >
                <div className="page-block-full-faq__faq-answer">
                  <Markdown>{faq.answer}</Markdown>
                </div>
              </Collapsible>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FullFaqBlockComponent
